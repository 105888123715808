<template>
  <a-dropdown :placement="placement">
    <a class="ant-dropdown-link">
      <slot name="suffix"></slot>
      <slot name="prefix"></slot>
    </a>
    <template #overlay>
      <a-menu>
        <a-menu-item
          v-for="o in optionsData"
          :key="o.id"
          :class="{ disable: o.disable }"
        >
          <a href title @click.prevent="() => change(o)">{{ o.label }}</a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
// eslint-disable-next-line
import { PropType } from 'vue'
// eslint-disable-next-line
import { IDropdownProps } from './interface'

import Dropdown from 'ant-design-vue/lib/dropdown'
import Menu from 'ant-design-vue/lib/menu'

interface IDropdownData {
  currentLabel: string
  optionsData: IDropdownProps[]
}

@Options({
  props: {
    options: {
      type: Array as PropType<IDropdownProps[]>,
      default: () => [],
    },
    current: {
      type: Number,
      default: 1,
    },
    reClick: {
      type: Boolean,
      default: false,
    },
    async: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'bottomLeft',
    },
  },
  components: {
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
  },
  data(): IDropdownData {
    return {
      currentLabel: this.options[0]
        ? (this.options[0] as IDropdownProps).label
        : '',
      optionsData: this.options,
    }
  },
  watch: {
    options: {
      immediate: true,
      handler: function (data) {
        this.optionsData = data
        this.currentLabel = data[0] ? data[0].label : ''
      },
    },
    current: function (currentId) {
      this.getCurrent(currentId)
    },
  },
  mounted() {
    this.getCurrent(this.current)
  },
  emits: ['change', 'click'],
  methods: {
    getCurrent: function (currentId: number) {
      const current = this.options.filter(({ id }: IDropdownProps) => {
        return id === currentId
      })
      if (current.length) {
        this.currentLabel = current[0].label
      }
    },
    change: function ({ label, id }: { label: string; id: number }) {
      if (!this.showLabel) {
        this.$emit('click', { label, id })

        return
      }

      if (!this.reClick) {
        if (this.currentLabel !== label) {
          if (!this.async) {
            this.currentLabel = label
          }
          this.$emit('change', { label, id })
        }
      } else {
        this.$emit('change', { label, id })
      }
    },
    init: function () {
      const curr = this.options[0]
      this.change(curr)
    },
  },
})
export default class DropdownComponent extends Vue {}
</script>
<style>
.ant-dropdown .ant-dropdown-content {
  background-color: #4c49af;
}
.ant-dropdown-menu .ant-dropdown-menu-item a {
  color: white;
}
.ant-dropdown-link {
  padding-right: 0;
}
i.dot {
  margin-right: 0;
}
</style>
