<template>
  <div class="content-">
    <div class="text-center">
      <img :src="require('@/assets/icons/decouverte.svg')" alt="" />
      <p class="messages">Opération réussie !</p>
      <p>{{ message }}</p>
      <button class="btn btn-primary" @click="backToList">Continuer</button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'DilypseAffiliateModalSuccess',
  props: {
    message: {
      type: String,
      default: 'Votre affilié a bien été crée, retrouvez-le dans le listing.',
    },
  },
  emits: ['continue'],
  methods: {
    backToList() {
      this.$emit('continue')
    },
  },
})
export default class DilypseAffiliateModalSuccess extends Vue {}
</script>
