
import { Options, Vue } from 'vue-class-component'
import FloatingLabel from '@/components/DataEntry/FloatingLabel/FloatingLabel.vue'
// eslint-disable-next-line
import { PropType } from 'vue'
import Modal from 'ant-design-vue/lib/modal'

interface IModalStatisticData {
  modalShow: boolean
}

@Options({
  components: {
    FloatingLabel,
    AModal: Modal,
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      require: true,
    },
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue: {
      handler(visible) {
        this.modalAction(visible)
      },
    },
  },
  data(): IModalStatisticData {
    return {
      modalShow: this.modelValue,
    }
  },
  methods: {
    onClose() {
      this.modalAction(false)
    },
    modalAction(visible: boolean) {
      this.modalShow = visible
      this.$emit('update:modelValue', visible)
    },
  },
})
export default class ModalComponent extends Vue {}
