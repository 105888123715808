
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'DilypseAffiliateModalSuccess',
  props: {
    message: {
      type: String,
      default: 'Votre affilié a bien été crée, retrouvez-le dans le listing.',
    },
  },
  emits: ['continue'],
  methods: {
    backToList() {
      this.$emit('continue')
    },
  },
})
export default class DilypseAffiliateModalSuccess extends Vue {}
